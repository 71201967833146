<template>
  <div class="container with-price-box">
    <div>
      <h1>Red Missile Chili 300g</h1>
      <p>
        One 300g bag with Red Missile chili that was grown here on the farm.
      </p>

      <picture>
        <a href="/img/shop/RedMissile-Bag300-1080.jpg"><img src="@/assets/images/RedMissile-Bag300-250.jpg" /></a>
      </picture>

      <p>
        In the picture you can see a part of our future forest garden. We have planted there as an experiment Red
        Missile chili as an experiment to have living roots in the soil. According to our concept, we offer the
        abundance provided by nature here in the farm store.
      </p>
      <picture>
        <a href="/img/shop/RedMissile-B02-1-1080.jpg"><img src="@/assets/images/RedMissile-B02-1-250.jpg" /></a>
      </picture>
      <p>
        The chili beans are harvested by hand and brought in a bucket for sorting.
      </p>

      <picture>
        <a href="/img/shop/RedMissile-B02-2-1080.jpg"><img src="@/assets/images/RedMissile-B02-2-250.jpg" /></a>
      </picture>
      <p>
        Sorting is then a rather lengthy process, because each chili bean must be separated individually from the branch
        and leaves. There are always a few that have not ripened and these must be sorted out.
      </p>
      <picture>
        <a href="/img/shop/RedMissile-B02-3-1080.jpg"><img src="@/assets/images/RedMissile-B02-3-250.jpg" /></a>
      </picture>
      <p>
        At the end, the chili beans are then packed in vacuum bags.
      </p>
      <picture>
        <a href="/img/shop/RedMissile-B02-4-1080.jpg"><img src="@/assets/images/RedMissile-B02-4-250.jpg" /></a>
      </picture>

      <p>
      </p>
    </div>
    <PriceBox product="09a13d5e-d7e6-4e18-a538-ad1dbc4fc559" />
  </div>
</template>

<script>
import PriceBox from '@/components/PriceBox.vue'

export default {
  name: "RedMissile100DE",
  components: {
    PriceBox,
  },
}

</script>
